import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Tabs,
  Form,
  Carousel,
  notification,
  Skeleton,
} from 'antd';

import {
  Card,
  SubHeader,
  AccountCard,
  BanksList,
} from 'components/gyramais';
import {
  Button,
  Modal,
  Layout,
  Progress,
} from 'components/sdk';
import {
  BusinessForm,
  BankAccountDrawer,
  BusinessUserCard,
  FindUserDrawer,
  BankAccountOptinDrawer,
} from 'components';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  formatAndNotificateError,
  needToGetToRegisterScreen,
} from 'utils';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ME,
  BANKS,
  SAVE_BUSINESS,
  SAVE_ADDRESS,
  SETTINGS,
} from './graphql';
import { stepVar, setStep } from '../../services/graphql/cache';

import './styles.less';
import BadesulPronampeBusinessFileStep from './components/BadesulPronampeBusinessFileStep';
import BadesulPronampeBusinessUserDrawer from './components/BadesulPronampeBusinessUserDrawer';

const { Content } = Layout;
const { useForm } = Form;
const { TabPane } = Tabs;

let carouselRef;

const capAmountMap = {
  'Pronampe Mulher': 50000,
  'Pronampe Geral': 50000,
  'Pronampe Emergencial CP': 100000,
  'Pronampe Emergencial SE': 100000,
};

const BusinessBadesulPronampeRegisterScreen = () => {
  const history = useHistory();
  const [form] = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentRange, setCurrentRange] = useState({ min: 0, max: 1 });
  const [bankDrawer, setBankDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [confirmBankModal, setConfirmBankModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState();
  const [findUserDrawer, setFindUserDrawer] = useState(false);
  const [selectedBusinessUser, setSelectedBusinessUser] = useState();
  const [businessUserDrawer, setBusinessUserDrawer] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [businessUserType, setBusinessUserType] = useState();
  const [bankStepActive, setBankStepActive] = useState(true);
  const [businessFilesStepActive, setBusinessFilesStepActive] = useState(true);
  const [businessUserStepActive, setBusinessUserStepActive] = useState(true);
  const [statusValue, setStatusValue] = useState();
  const [editedBankAccount, setEditedBankAccount] = useState();
  const [businessUsersRevised, setBusinessUsersRevised] = useState([]);
  const [businessDocumentsRevised, setBusinessDocumentsRevised] = useState([]);
  const [isCreation, setIsCreation] = useState(true);

  const [bankAccountOptInDrawer, setBankAccountOptInDrawer] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  const { loadingActions, checkActions } = usePartnerActionsContext();
  const { currentContext } = useBusinessLoanContext();

  const { partner } = currentContext;
  const headerProps = {
    business_form_step: {
      title: (
        <>
          Preencha os dados da sua empresa.
        </>
      ),
      subtitle: 'É preciso reconhecer e confiar para dividir um sonho com alguém. Queremos crescer junto com você, e pra isso, precisamos te conhecer melhor!',
    },

    business_files_step: {
      title: statusValue === 'check-documents'
        ? ('Revise os dados da sua empresa')
        : ('Envie pra gente os documentos da empresa cadastrada.'),
      subtitle: 'Vamos precisar desses documentos para concluir sua aprovação. Falta pouco para acabar!',
    },

    bank_step: {
      title: statusValue === 'check-documents'
        ? ('Revise o extrato bancário da conta recusada.')
        : ('Selecione os bancos e adicione seus extratos.'),
      subtitle: statusValue === 'check-documents'
        ? ('Nossa equipe encontrou algo de errado durante a análise. Confira seus dados e revise sua(s) conta(s) bancária(s). Em caso de dúvidas, entre em contato conosco.')
        : (
          <>
            Adicione
            {' '}
            <b className="white-label-text">TODOS</b>
            {' '}
            os bancos que sua empresa tem conta.
            Na lista de bancos conectados, selecione qual será utilizado para receber o empréstimo.
          </>
        ),
    },

    business_user_step: {
      title: statusValue === 'check-documents'
        ? ('Revise os dados pessoais da(s) pessoa(s) abaixo.')
        : ('Insira os dados dos sócios/representantes/avalistas\nda empresa.'),
      subtitle: statusValue === 'check-documents'
        ? ('Nossa equipe encontrou algo de errado durante a análise. Confira os dados das pessoas abaixo e revise o necessário. Em caso de dúvidas, entre em contato conosco.')
        : (
          <>
            Para receber seu financiamento,
            {' '}
            <b className="white-label-text">
              TODOS
            </b>
            {' '}
            devem assinar o contrato.
            As validações para assinatura serão feitas por e-mail e SMS,
            por isso é muito importante que as informações estejam corretas.
          </>
        ),
    },
  };

  const {
    data: {
      me = {},
    } = {},
    refetch,
    loading: meLoading,
  } = useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ me: { currentBusiness: { loans } = {} } = {} }) => {
      setStatusValue(loans[0]?.status?.value);

      if (!needToGetToRegisterScreen(loans)) {
        setStep(-1);
        history.push('/cadastro/status');
        // eslint-disable-next-line
      } else if (stepVar() == -1) {
        setStep(0);
      }

      if (loans?.[0]?.status?.value === 'review') {
        const formattedSteps = me?.currentBusiness?.revision.map(({ completedAt, status }, i) => {
          if (checkActions(['partner-is-banker']) && status === 'pending-bank-account') {
            return undefined;
          }

          if (!completedAt) {
            return i;
          }
          if ((status === 'pending-users') && (completedAt)) {
            headerProps.business_user_step = {
              title: 'Estamos salvando suas informações',
              // eslint-disable-next-line max-len
              subtitle: 'Aguarde enquanto estamos salvando as alterações.',
            };
          } else {
            headerProps.business_user_step = {
              title: 'Insira os dados dos responsáveis da empresa.',
              subtitle: (
                <>
                  Para receber seu financiamento,
                  {' '}
                  <b className="white-label-text">TODOS</b>
                  {' '}
                  os sócios devem assinar o contrato que enviamos por SMS.
                  Por isso, é muito importante que as informações estejam corretas.
                </>
              ),
            };
          }
          return undefined;
        }).filter((i) => i !== undefined);

        if ((!formattedSteps.includes(currentStep) && (currentStep < currentRange.max - 1))) {
          setCurrentStep(currentStep + 1);
        }
      }
    },
  });

  const {
    data: {
      settings: {
        necessaryDocumentsFlow,
      } = {},
    } = {},
  } = useQuery(SETTINGS);

  const businessUsers = me?.currentBusiness?.users || [];
  const currentLoan = currentContext?.loan ?? {};
  const finalAmount = currentLoan?.finalAmount;

  const { portfolioName } = currentLoan;
  const capAmount = capAmountMap[portfolioName];
  const formattedCapAmount = (new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })).format(capAmount);

  const fromCooperativa = currentLoan?.additionalInfo?.creationData?.dadosCooperativa;

  const handleNextCarouselStep = () => {
    if (currentStep < currentRange.max - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setRedirectLoading(true);
      setTimeout(() => {
        history.push('/cadastro/status');
      }, 5000);
    }
  };

  const filteredBusinessUsers = [{
    label: 'Sócios',
    type: 'socio',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'socio'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }, {
    label: 'Avalistas',
    type: 'avalista',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'avalista'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }, {
    label: 'Representantes Legais',
    type: 'representantelegal',
    users: businessUsers
      ?.filter(({ types }) => types.some(({ value }) => value === 'representantelegal'))
      .sort((a, b) => Number(!!a.completedAt) - Number(!!b.completedAt)),
  }];

  const progressPercent = (100 / (currentRange.max + 1)) * (currentStep + 1);

  useQuery(BANKS, {
    onCompleted: async ({ banks = [] }) => {
      setSelectedBank(banks[0]);
    },
  });

  const [saveBusiness, { loading: businessLoading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      handleNextCarouselStep();
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const [saveAddress, { loading: addressLoading }] = useMutation(SAVE_ADDRESS, {
    oonError: (error) => formatAndNotificateError(error),
  });

  const isLoading = businessLoading
    || addressLoading
    || redirectLoading
    || meLoading;
  const checkForm = async (keys) => {
    const params = {};
    const formsVariable = await form.getFieldsValue();

    Object.keys(formsVariable).map((key) => {
      if (keys.includes(key)) params[key] = formsVariable[key];
      return true;
    });

    return params;
  };

  const handleEditBankAccount = ({ bankAccount }) => {
    setEditedBankAccount(bankAccount);
    setBankDrawer(true);
  };

  const additionalFiles = me
    ?.currentBusiness
    ?.additionalFiles
    ?.reduce((additionalFiles, currentFile) => {
      if (!currentFile.parent) {
        return additionalFiles;
      }

      // eslint-disable-next-line no-param-reassign
      additionalFiles[currentFile.parent.name] = currentFile;
      return additionalFiles;
    }, {});

  const steps = useMemo(() => {
    const array = [
      (statusValue !== 'check-documents') && {
        // ====== BUSINESS_FORM_STEP ========
        name: 'business_form_step',
        pending: 'pending-business-infos',
        content: (
          <div className={`carousel-content ${statusValue !== 'check-documents' ? 'current' : ''}`} key={currentStep}>
            <Row align="middle">
              <Col xs={24}>
                <Card
                  className="business-form-card"
                  title={(
                    <>
                      <h3 className="no-margin-bottom secondary-text">Queremos te conhecer</h3>
                      <h3 className="no-margin-bottom">
                        Insira as informações básicas da sua empresa
                      </h3>
                    </>
                  )}
                >
                  <BusinessForm
                    form={form}
                    flow={partner?.flow}
                    address={me?.currentBusiness?.address || {}}
                    business={me?.currentBusiness}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        ),
        handleSubmit: async () => {
          const addressParams = await checkForm(['postalCode', 'street', 'district', 'number', 'state', 'city', 'complement']);

          const postalCodeLength = addressParams.postalCode?.replace(/[_-\s]/g, '').length;

          if (postalCodeLength !== 8) {
            notification.error({ message: 'Preencha o CEP com 8 dígitos' });
            return;
          }
          const response = await saveAddress({
            variables: {
              ...addressParams,
              businessId: me?.currentBusiness?.id,
              id: me?.currentBusiness?.address?.id,
            },
          });
          if (!response) {
            return;
          }

          const params = await checkForm(['name', 'legalName', 'cnpj', 'phoneNumber']);
          await saveBusiness({
            variables: { ...params, id: me?.currentBusiness?.id },
          });
        },
      },
      businessFilesStepActive && {
        // ====== BUSINESS_FILES_STEP ========
        name: 'business_files_step',
        pending: 'pending-docs',
        content: (
          <div className="carousel-content current" key={currentStep}>
            <Row gutter={12}>
              <Col span={24}>
                <BadesulPronampeBusinessFileStep
                  form={form}
                  refetch={refetch}
                  flow={partner?.flow}
                  line={me?.currentBusiness?.additionalInfo?.clientCreditLine}
                  type={me?.currentBusiness?.type?.split(' ')[0]}
                  files={{
                    proofOfAddress: me?.currentBusiness?.address?.proofOfAddress,
                    bylaws: me?.currentBusiness?.bylaws,
                    revenueStatement: me?.currentBusiness?.revenueStatement,
                    cadastur: me?.currentBusiness?.cadastur,
                    images: me?.currentBusiness?.images,
                    majorityPartnerSelfie: me?.currentBusiness?.majorityPartnerSelfie,
                  }}
                  additionalFiles={additionalFiles}
                  statements={me?.currentBusiness?.revenueStatementValues}
                  segment={me?.currentBusiness?.segment?.value}
                  necessaryDocumentsFlow={necessaryDocumentsFlow}
                  lemitValidated={me?.currentBusiness?.address?.lemitValidated}
                  address={me?.currentBusiness?.address}
                  hideNotAnalyzedFiles={statusValue === 'check-documents'}
                  businessDocumentsRevised={businessDocumentsRevised}
                  setBusinessDocumentsRevised={setBusinessDocumentsRevised}
                />
              </Col>
            </Row>
          </div>
        ),
        handleSubmit: async () => {
          let valuesToCheck = [];

          if (statusValue !== 'check-documents') {
            valuesToCheck = [
              'bylawsFileId',
              'revenueStatementFilesIds',
              'cadasturFileId',
              'revenueStatementValues',
              'imagesFilesIds',
              'proofOfAddressFileId',
              'naturalDisasterDocumentFileId',
              'regularityDocumentFileId',
              'raisPersonFileId',
            ];
          }
          if (necessaryDocumentsFlow !== 'B') {
            valuesToCheck.push('proofOfAddressFileId', 'majorityPartnerSelfieFileId');
          }
          if (statusValue === 'check-documents') {
            if (me?.currentBusiness?.address?.analysis?.approved === false) {
              valuesToCheck.push('proofOfAddressFileId');
            }
            if (me?.currentBusiness?.bylaws?.analysis?.approved === false) {
              valuesToCheck.push('bylawsFileId');
            }
          }

          const params = await checkForm(valuesToCheck);

          if (statusValue === 'check-documents') {
            if ((Object.keys(params).length !== businessDocumentsRevised.length)
              || businessDocumentsRevised.length === 0) {
              notification.error({ message: 'Revise os documentos antes de prosseguir.' });
              return;
            }
          }

          if (statusValue !== 'check-documents') {
            if (!params?.bylawsFileId) {
              notification.error({ message: 'Faça upload do contrato social antes de prosseguir.' });
              return;
            }

            if (!params?.proofOfAddressFileId) {
              notification.error({ message: 'Faça upload do comprovante de endereço antes de prosseguir.' });
              return;
            }

            if (!params?.raisPersonFileId && !additionalFiles.raisPerson) {
              notification.error({ message: 'Faça upload do documento de rais antes de prosseguir.' });
              return;
            }
          }
          // params.badescNaturalDisasterDocumentFileId = params.naturalDisasterDocumentFileId;
          // params.badescRegularityDocumentFileId = params.regularityDocumentFileId;

          await saveBusiness({
            variables: { ...params, id: me?.currentBusiness?.id },
          });
        },
      },
      bankStepActive && {
        // ====== BANK_STEP ========
        name: 'bank_step',
        pending: 'pending-bank-account',
        content: (
          <div className="carousel-content current" key={currentStep}>
            <Tabs
              className="bank-actions"
              activeKey={activeTab}
              onChange={(e) => setActiveTab(`${e}`)}
            >
              <TabPane
                tab={(
                  <h5 className="no-margin-bottom">
                    {statusValue === 'check-documents' ? 'Conectar bancos' : 'Lista de bancos'}
                  </h5>
                )}
                key="1"
              >
                <Row>
                  <BanksList
                    onSet={(selectedBank) => {
                      setSelectedBank(selectedBank);
                      setBankDrawer(true);
                    }}
                  />
                </Row>
              </TabPane>

              <TabPane
                tab={(
                  <h5 className={`bank-tooltip no-margin-bottom ${me?.currentBusiness?.bankAccounts?.length === 0 ? ' gray-text' : ''}`}>
                    {`${statusValue === 'check-documents'
                      ? 'Extratos bancários'
                      : 'Suas contas'} (${me?.currentBusiness?.bankAccounts?.length || '0'})`}
                  </h5>
                )}
                key="2"
                disabled={me?.currentBusiness?.bankAccounts?.length === 0}
              >
                {me?.currentBusiness?.bankAccounts?.map((account) => (
                  <AccountCard
                    key={account.id}
                    id={account.id}
                    code={account.bank.code}
                    flow={partner?.flow}
                    selected={me?.currentBusiness?.bankAccount?.id === account.id}
                    status={{ name: 'CONECTADA', value: 'active' }}
                    logo={account?.bank?.logo?.url}
                    refetchBankAccount={refetch}
                    platformName={account?.bank?.name}
                    account={{
                      branchCode: account.branchCode,
                      accountNumber: account.accountNumber,
                      currentAccount: account.currentAccount ? 'corrente' : 'poupanca',
                      statementFileIds: account.statements.map((statement) => statement.id),
                      bank: account?.bank,
                      statementFiles: account.statements,
                      id: account.id,
                    }}
                    type="BankAccount"
                    bypass
                    currentAccount={account.currentAccount}
                    createdByPluggy={account.origin === 'pluggy'}
                    approved={account.analysis?.approved}
                    onEdit={handleEditBankAccount}
                  />
                ))}
              </TabPane>
            </Tabs>
          </div>
        ),
        handleSubmit: async () => {
          if (statusValue === 'check-documents'
            && me?.currentBusiness?.bankAccounts?.some(
              (account) => account.analysis?.approved === false,
            )) {
            notification.error({ message: 'Para continuar é necessário revisar suas informações bancárias.' });
            return;
          }
          if (activeTab === '1') {
            // Caso estiver na lista de bancos para se cadastrar,
            // ir para a lista de bancos cadastrada
            setActiveTab('2');
          } else if (me?.currentBusiness?.bankAccounts?.length < 2
            || me?.currentBusiness?.bankAccounts
              ?.filter((bankAccount) => bankAccount.origin === 'pluggy').length === 1) {
            setStep(-1);
            handleNextCarouselStep();

          } else {
            // Tudo certo, pode confirmar e continuar
            setConfirmBankModal(true);
          }
        },
      },
      businessUserStepActive && {
        // ====== BUSINESS_USER_STEP ========
        name: 'business_user_step',
        pending: 'pending-users',
        content: (statusValue !== 'review' && !isLoading) && (
          <div className="carousel-content current" key={currentStep}>
            <Tabs tabPosition="top" animated>
              {filteredBusinessUsers.map(({
                label,
                type,
                users = [],
              }, index) => {
                const usersIds = users?.map(({ user: { id } }) => id);
                const civilPartnerIds = type === 'avalista' ? users.map(({ user }) => user?.civilPartner?.id) : [];
                const count = new Set([
                  ...usersIds.filter((i) => i),
                  ...civilPartnerIds.filter((i) => i),
                ]).size;

                return (
                  <TabPane
                    tab={(
                      <h5 className="no-margin-bottom">
                        {label}
                        {' '}
                        (
                        {count}
                        )
                      </h5>
                    )}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index + 1}
                  >
                    <Row gutter={[14, 14]} className="add-business-user-buttons-container">
                      <Col xs={24} sm={24} md={8} lg={6} xl={5}>
                        <Button
                          block
                          onClick={() => {
                            setBusinessUserType('socio');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar sócio(a)
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={6} xl={5}>
                        <Button
                          block
                          color="white"
                          onClick={() => {
                            setBusinessUserType('avalista');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar avalista
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={7} xl={6}>
                        <Button
                          block
                          color="white"
                          onClick={() => {
                            setBusinessUserType('representantelegal');
                            setFindUserDrawer(true);
                            setIsCreation(true);
                          }}
                        >
                          Adicionar representante legal
                        </Button>
                      </Col>

                    </Row>

                    <br />

                    <div className="business-users-list-container">
                      {users?.map((businessUser) => (
                        <BusinessUserCard
                          ids={users.map(({ user: { id } }) => id)}
                          revised={businessUsersRevised.includes(businessUser?.id)}
                          businessUser={businessUser}
                          loanStatus={statusValue}
                          key={businessUser.id}
                          refetch={refetch}
                          onClickEdit={() => {
                            setSelectedBusinessUser(businessUser);
                            setBusinessUserType(type);
                            setBusinessUserDrawer(true);
                            setIsCreation(false);
                          }}
                          section={{
                            type,
                            label,
                          }}
                          forceOpenToEdit
                        />
                      ))}
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        ),
        handleSubmit: async () => {
          if (businessUsers.length === 0) {
            notification.error({
              message: 'Adicione ao menos um responsável da empresa',
            });
            return;
          }

          if (me?.currentBusiness?.businessRequests.some((businessRequest) => businessRequest.type.value === 'add-avalist')) {
            notification.error({
              message: 'É necessário a adição de um novo avalista.',
            });
            return;
          }

          if (me?.currentBusiness?.users.some(
            ({ user }) => user?.address?.analysis?.approved === false
              || user?.proofOfIncome?.analysis?.approved === false,
          )
            && statusValue === 'check-documents'
          ) {
            notification.error({
              message:
                'Revise todos os usuários antes de continuar.',
            });
            return;
          }

          if (me?.currentBusiness?.users.some((user) => !user?.completedAt)) {
            notification.error({
              message:
                'Complete as informações de todos os responsáveis antes de continuar',
            });
            return;
          }

          if (!me?.currentBusiness?.users.some((user) => user.types.find((type) => type.value === 'socio'))) {
            notification.error({
              message: 'É necessário pelo menos um sócio para continuar',
            });
            return;
          }

          if (!me?.currentBusiness?.users.some((user) => user.types.find((type) => type.value === 'representantelegal'))) {
            notification.error({
              message: 'É necessário pelo menos um representante legal para continuar',
            });
            return;
          }

          if (finalAmount > capAmount && !fromCooperativa) {
            const hasUserWithTwoProofOfRealState = businessUsers.some(({ user }) => {
              const hasTwoProofOfRealState = (user.additionalFiles || [])
                .reduce((acc, { parent }) => {
                  if (parent?.name === 'proofOfRealState') {
                    // eslint-disable-next-line no-param-reassign
                    acc += 1;
                  }

                  return acc;
                }, 0) >= 2;

              return hasTwoProofOfRealState;
            });

            if (!hasUserWithTwoProofOfRealState) {
              notification.error({
                message: `Para operações acima de R$ ${formattedCapAmount} é necessário um avalista qualificado (obrigatório ter pelo menos duas matrículas de imóveis adicionadas)`,
              });
              return;
            }
          }

          setStep(-1);
          await saveBusiness({
            variables: { id: me?.currentBusiness?.id, completed: true },
          });
        },
      },
    ];

    return array.filter(Boolean);
  }, [
    businessFilesStepActive,
    statusValue,
    bankStepActive,
    businessUserStepActive,
    me,
    businessDocumentsRevised,
    statusValue,
    isLoading,
    filteredBusinessUsers,
    currentStep,
    selectedBank,
    businessUsers,
  ]);

  useEffect(() => {
    if (stepVar() && stepVar() !== -1) {
      if (statusValue !== 'check-documents') {
        setCurrentStep(stepVar());
      }
    }
  }, []);

  useEffect(() => {
    refetch();

    if (!carouselRef) {
      return;
    }
    if (statusValue !== 'check-documents' && stepVar() !== -1 && currentStep !== -1) {
      setStep(currentStep);
    }

    if (statusValue === 'review') {
      setCurrentStep(currentStep - 1);
    }

    setTimeout(() => carouselRef.goTo(currentStep), 200);
  }, [currentStep]);

  const isSubmitButtonDisabled = () => {
    const {
      bankAccount,
    } = me?.currentBusiness || {};

    switch (steps[currentStep]?.name) {
      case 'bank_step': {
        if (!bankAccount) {
          return true;
        }

        break;
      }

      case 'business_user_step': {
        if (me?.currentBusiness?.users?.length === 0) {
          return true;
        }
        break;
      }

      default:
        return false;
    }

    return false;
  };

  const hasBackButton = currentStep > 0
    && ((statusValue !== 'review') || (currentStep - 1) >= 0);

  useEffect(() => {
    if (checkActions(['partner-is-banker'])) {
      setBankStepActive(false);
    }
  }, [loadingActions]);

  useEffect(() => {
    if (statusValue === 'check-documents') {
      if (me?.currentBusiness?.address?.analysis?.approved !== false
        && me?.currentBusiness?.bylaws?.analysis?.approved !== false) {
        setBusinessFilesStepActive(false);
      }
      if (!me
        ?.currentBusiness
        ?.bankAccounts
        .some((bankAccount) => bankAccount?.analysis?.approved === false)) {
        setBankStepActive(false);
      }
      const verificationAddressBusinessUser = businessUsers.some(
        ({ user }) => user?.address?.analysis?.approved === false,
      );
      const verificationIncomeBusinessUser = businessUsers.some(
        ({ user }) => user?.proofOfIncome?.analysis?.approved === false,
      );

      const verificationRequestToAddAvalist = me?.currentBusiness?.businessRequests?.some((businessRequest) => businessRequest?.type?.value === 'add-avalist');

      if (!verificationAddressBusinessUser
        && !verificationIncomeBusinessUser
        && !verificationRequestToAddAvalist) {
        setBusinessUserStepActive(false);
      }
      setActiveTab('2');
    }
    if (!meLoading) {
      setStep(0);
      setContentLoading(false);
    }
  }, [statusValue]);

  useEffect(() => {
    setCurrentRange({ min: 0, max: steps?.length || 0 });
    if (steps?.length === 0 && !isLoading) {
      setStep(-1);
      history.push('/cadastro/status');
    }
  }, [steps?.length]);

  return (
    <Layout id="business-register-screen">
      <SubHeader
        title={headerProps[steps[currentStep]?.name]?.title}
        subtitle={headerProps[steps[currentStep]?.name]?.subtitle}
        extraButtonsPlacement="bottom"
      />
      {!contentLoading ? (
        <>
          <Progress
            percent={progressPercent}
            bordered
            rounded={false}
            borderPlacement="bottom"
            progressColor="primary-button"
          />

          <Content>
            {steps.length > 0 && (
              <Carousel
                ref={(c) => { carouselRef = c; }}
                dots={false}
                swipe={false}
                swipeToSlide={false}
                draggable={false}
                accessibility={false}
              >
                {steps[currentStep].content}
              </Carousel>
            )}
          </Content>
        </>
      ) : (
        <Skeleton active />
      )}

      <div className="global-footer-wrapper">
        <div className={`footer ${(!hasBackButton || statusValue === 'check-documents') && 'no-back-btn'}`}>
          {hasBackButton && statusValue !== 'check-documents' && (
            <Button
              link
              disabled={isLoading}
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              {'< Voltar'}
            </Button>
          )}

          <Button
            id="business-register-next-button"
            disabled={isSubmitButtonDisabled()}
            loading={isLoading}
            onClick={steps[currentStep]?.handleSubmit}
            data-cy="business-register-next-button"
          >
            Confirmar e prosseguir
          </Button>
        </div>
      </div>

      {steps[currentStep]?.name === 'bank_step' && (
        <>
          <BankAccountDrawer
            onPending
            visible={bankDrawer}
            selectedBank={selectedBank}
            selectedBankAccount={editedBankAccount}
            refetch={refetch}
            onClose={() => {
              setBankDrawer(false);
              setSelectedBank();
              setEditedBankAccount();
            }}
            onSelectMain={() => {
              setBankDrawer(false);
              setSelectedBank();
              setActiveTab('2');
            }}
          />

          {me?.currentBusiness?.bankAccount && (
            <Modal
              visible={confirmBankModal}
              onCancel={() => setConfirmBankModal(false)}
              centered
              title="Escolha a conta na qual o crédito será desembolsado"
              okButton={{
                id: 'confirm-bank-account-button',
                text: 'Desembolsar nesta conta',
                onClick: () => {
                  setConfirmBankModal(false);
                  handleNextCarouselStep();
                },
              }}
            >
              <Row gutter={[16, 16]}>
                {me?.currentBusiness?.bankAccounts?.map((account) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <AccountCard
                      key={account.id}
                      refetch={refetch}
                      id={account.id}
                      code={account.bank.code}
                      flow={partner?.flow}
                      selected={me?.currentBusiness?.bankAccount?.id === account.id}
                      status={{ name: 'CONECTADA', value: 'active' }}
                      logo={account?.bank?.logo?.url}
                      refetchBankAccount={refetch}
                      platformName={account?.bank?.name}
                      account={{
                        branchCode: account.branchCode,
                        accountNumber: account.accountNumber,
                      }}
                      type="BankAccount"
                      currentAccount={account.currentAccount}
                      mobileStyle
                      createdByPluggy={account.origin === 'pluggy'}
                      currentBankAccountCreatedByPluggy={me
                        ?.currentBusiness
                        ?.bankAccount
                        ?.origin === 'pluggy'}
                      removable={false}
                    />
                  </Col>
                ))}
              </Row>
            </Modal>
          )}
        </>
      )}

      {steps[currentStep]?.name === 'business_user_step' && (
        <>
          <FindUserDrawer
            businessId={me?.currentBusiness?.id}
            visible={findUserDrawer}
            onClose={() => setFindUserDrawer(false)}
            refetch={refetch}
            businessUsers={businessUsers}
            type={businessUserType}
            onAddNewUser={(cpf) => {
              setFindUserDrawer(false);
              setSelectedBusinessUser({ id: undefined, cpf, types: [businessUserType] });
              setBusinessUserDrawer(true);
            }}
          />

          <BadesulPronampeBusinessUserDrawer
            businessId={me?.currentBusiness?.id}
            flow={partner?.flow}
            visible={businessUserDrawer}
            onClose={() => {
              setBusinessUserDrawer(false);
              setSelectedBusinessUser();
            }}
            type={businessUserType}
            businessUsersRevised={businessUsersRevised}
            setBusinessUsersRevised={setBusinessUsersRevised}
            setSelectedBusinessUser={setSelectedBusinessUser}
            refetch={refetch}
            isCreation={isCreation}
            businessUser={selectedBusinessUser}
            me={selectedBusinessUser?.user?.id === me?.id}
            onAddNewUser={() => {
              setBusinessUserDrawer(false);
              setSelectedBusinessUser();
              setFindUserDrawer(true);
            }}
            necessaryDocumentsFlow={necessaryDocumentsFlow}
            loanStatus={statusValue}
            finalAmount={finalAmount}
            capAmount={capAmount}
            fromCooperativa={fromCooperativa}
          />

          <BankAccountOptinDrawer
            visible={bankAccountOptInDrawer}
            onFinish={() => {
              setBankAccountOptInDrawer(false);
              setStep(-1);
              saveBusiness({
                variables: { id: me?.currentBusiness?.id, completed: true },
              });
            }}
            onClose={() => setBankAccountOptInDrawer(false)}
          />
        </>
      )}
    </Layout>
  );
};

export default BusinessBadesulPronampeRegisterScreen;
